import * as React from 'react'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { navigate } from 'gatsby'
import Layout from '@/container/Layout'
import TextInput from '@/components/formFields/TextInput'
import Password from '@/components/formFields/Password'
import Notification from '@/components/user/Notification'
import Button from '@/components/buttons/Button'
import Avatar from '@/components/user/Avatar'
import Text from '@/utils/Text'
import Link from '@/utils/Link'
import useFocusOnFirstInput from '@/hooks/useFocusOnFirstInput'
import useFormState from '@/hooks/useFormState'
import * as m from '@/utils/messages'
import { unconfirmedSupportNumbers } from '@/utils/unconfirmedSupportNumbers'
import ReCAPTCHA from "react-google-recaptcha";
import { bool } from 'prop-types';

// see below the default export. there
// are some other Text nodes that will
// be part of this error component
function PasswordError() {
	return (
		<Text as="span">
			Try again or{' '}
			<Link
				className="text-white underline"
				to="/forgotten-password/"
				data-testid="reset-password-link"
				onClick={e => {
					// we are inside a label... we need
					// to prevent the event from bubbling
					e.stopPropagation()
				}}
			>
				reset your password
			</Link>
		</Text>
	)
}

function EmailError() {
	return <Text as="span">{m.LOGIN_EMAIL_ERROR}</Text>
}

function Login({ssoOnly}) {
	const {
		register,
		unregister,
		handleSubmit,
		errors,
		formState,
		setError,
		setValue
	} = useForm({
		mode: 'onBlur'
	})
	const formRef = React.useRef()

	const {
		loggedIn,
		loginError,
		passwordChanged,
		benefitBrandName,
		supportEmail,
		supportNumber,
		avatar,
		cmsData
	} = useSelector(state => state.user)

	const isSSOActive = cmsData?.sso_access_only

	const [ showRecaptcha, setShowRecaptcha ] = React.useState(false)

	const dispatch = useDispatch()

	// use the form ref to find the first input to focus
	useFocusOnFirstInput(formRef)

	// we need to make sure our form is aware
	// of the recaptche field. we can't use refs
	// to register the field, so instead we'll
	// manually register the field
	React.useEffect(() => {
		register(
			{
				name: 'recaptcha'
			},
			{
				required: {
					value: showRecaptcha,
					message: 'This field is required'
				}
			}
		)

		// setValue(false)
	}, [register, unregister, showRecaptcha])

	// if the user is already logged in,
	// or the login attempt is successful
	// redirect to the app home page
	React.useEffect(() => {
		if (loggedIn || (window.localStorage.getItem('loggedIn') === 'true' && window.localStorage.getItem('loginCredentials') !== null)) {
			navigate('/app/home/')
		}
	}, [loggedIn, dispatch, isSSOActive])

	// submit the form
	// we only need to dispatch the submit action
	// we will handle the response via the user store
	// if successful, loggedIn will be set to true
	// triggering the next hook, if it fails loginError
	// will be set, triggering the second hook
	const onSubmit = async data => {
		const response = await dispatch({
			type: 'user/REQUEST_ACCESS_TOKEN',
			payload: data
		})

		setShowRecaptcha(!!response?.showRecaptcha)

	}

	// update the loading state
	// based on whether the form is
	// currently submitting or not.
	useFormState(formState.isSubmitting)

	// only show the submit errors if a submission has been
	// attempted, and the submission set the loginError
	// and, only if the form is not currently being submitted
	const showFormErrors =
		formState.submitCount > 0 && !!loginError && !formState.isSubmitting  && loginError != 'Please accept cookies before logging in'

	// if the login attempted failed
	React.useLayoutEffect(() => {
		if (showFormErrors) {
			setError('email', 'notMatch', <EmailError />)
			setError('password', 'notMatch', <PasswordError />)
		}
	}, [loginError, setError, formState.isSubmitting, showFormErrors])

	return (
		<Layout className="flex-grow pt-lg lg:pt-2xl">
			<Helmet>
				<title>{`Login | ${benefitBrandName}`}</title>
			</Helmet>
			{passwordChanged && (
				<Notification
					level="success"
					className="text-center -mt-md mb-lg lg:-mt-lg lg:mb-xl font-h-light"
					data-testid="notification"
				>
					{m.PASSWORD_CHANGED}
				</Notification>
			)}

			{loginError && (
				<Notification
					level="error"
					className="text-center -mt-md mb-lg lg:-mt-lg lg:mb-xl font-h-light"
					data-testid="error"
				>
					<Text
						as="p"
						className="w-full text-mob-sm"
						dangerouslySetInnerHTML={{
							__html: loginError
						}}
					/>

				</Notification>
			)}

			{!ssoOnly && (<form
				ref={formRef}
				data-testid="login-form"
				noValidate
				method="post"
				onSubmit={handleSubmit(onSubmit)}
				className="w-full grid-flow-row-dense mx-auto pb-lg max-w-login lg:max-w-none lg:grid lg:grid-cols-12 lg:col-gap-2xl ie:flex ie:flex-wrap"
			>
				<div className="mb-lg lg:col-start-2 lg:col-end-7 lg:mb-2xl ie:w-1/2 ie:px-xl">
					{avatar && (
						<Avatar
							image={avatar}
							className={'w-wilb-mob md:w-wilb h-auto mb-xl'}
						/>
					)}
					<Text
						as="h1"
						className="w-full leading-tight font-h-light text-mob-2xl md:text-2xl mb-lg"
					>
						{m.WELCOME_MESSAGE(benefitBrandName)}
					</Text>
				</div>
				<fieldset className="mb-xl lg:mb-0 lg:col-start-8 lg:col-end-11 ie:w-1/2 ie:px-2xl">
					<label htmlFor="email" className="block mb-lg">
						<Text as="span" className="block text-mob-lg mb-lg font-h-light">
							Email address
						</Text>
						<TextInput
							id="email"
							name="email"
							type="email"
							ref={register({
								required: {
									value: true,
									message: m.REQUIRED_FIELD
								},
								pattern: {
									value: /^[A-Z0-9._%'+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: m.ENTER_VALID_EMAIL
								}
							})}
							error={errors?.email?.message}
							autoComplete="username"
						/>
					</label>
					<Password
						title="Password"
						id="password"
						name="password"
						ref={register({
							required: {
								value: true,
								message: <PasswordError />
							}
						})}
						error={errors?.password?.message}
						showForgttenLink
						autoComplete="current-password"
						showInputClassName="md:absolute md:bottom-md md:left-100"
					/>
					{errors.recaptcha && (
						<Notification size="sm" level="error" className="mb-sm p-sm">
							Please confirm that you are not a robot!
						</Notification>
					)}

				 {(showRecaptcha && !formState.isSubmitting) && (
						<ReCAPTCHA
							className="mb-lg"
							sitekey={RECAPTCHA_PUBLIC_KEY}
							onChange={value => setValue('recaptcha', value)}
							data-testid="recaptcha"
						/>
				 )}


				</fieldset>

				<div className="mb-xl lg:col-start-8 lg:col-end-11 ie:w-1/2 ie:order-last ie:px-2xl">
					<Button
						data-testid="login-submit"
						className="px-2xl"
						type="submit"
						disabled={formState.isSubmitting}
					>
						Login
					</Button>
				</div>
				<div className="mb-lg lg:col-start-2 lg:col-end-7 lg:mb-2xl ie:w-1/2 ie:px-2xl">
					<Text
						as="p"
						className="w-full text-mob-sm"
						dangerouslySetInnerHTML={{
							__html: (unconfirmedSupportNumbers.includes(supportNumber) ? 
								m.TROUBLE_LOGGING_IN_WITHOUT_TEL({ supportEmail }) :	m.TROUBLE_LOGGING_IN_WITH_TEL({ supportEmail, supportNumber }))
						}}
					/>
				</div>
                 </form>)}
		</Layout>
	)
}

Login.propTypes = {
	ssoOnly: bool
}

export default Login

/*
function PasswordError() {
	return (
		<>
			<Text as="span">
				Try again or{' '}
				<Link
					className="text-white underline"
					to="/forgotten-password/"
					onClick={e => {
						e.stopPropagation()
					}}
				>
					reset your password
				</Link>
			</Text>
			<p className="mt-md">
				In a rush?{' '}
				<Link className="text-white underline" to="/sso/">
					Log in with a magic link!
				</Link>
			</p>
		</>
	)
}

function EmailError() {
	return (
		<>
			<Text as="span">We can't find a match! Try again or contact us</Text>
			<Text as="span">
				We can't find a match! Try again or contact{' '}
				<Link
					onClick={e => e.stopPropagation()}
					className="text-white underline"
					to="/contact-us/"
				>
					support
				</Link>
			</Text>
		</>
	)
}


{RELEASE_ROCK && (
	<div className="flex items-center justify-center text-primary mb-xl md:justify-start">
		<Link
			className="text-mob-sm md:text-sm hover:underline"
			to="/sso/"
		>
			Use a magic link instead
		</Link>
		<ToolTip className="-top-2xs" id="a" title="hello">
			<Text className="text-base text-grey">tip</Text>
		</ToolTip>
	</div>
)}
{RELEASE_ROCK && (
	<Checkbox
		value="rememberMe"
		error={errors.rememberMe}
		touched={touched.rememberMe}
		id="rememberMe"
		name="rememberMe"
		className="mb-xl"
		onBlur={handleBlur}
		onChange={handleChange}
		defaultChecked={values.rememberMe}
		data-testid="remember"
	>
		Remember me
	</Checkbox>
)}
*/
